import * as React from "react"
import { navigate, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import BackgroundImage from 'gatsby-background-image'
import get from "lodash/get"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/hero"
import "../../scss/pages/index.scss"
import "../../scss/pages/toolkit.scss"

class PortalTookit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingIframe: true,
      loggedFlag: true,
      modalLink: null,
      modalTitle: null,
    }
  }

  resourceHandler(target,title,large) {
    if (large !== null) {
      this.setState({modalLink: "/videos/"+large, modalTitle: title})
    } else {
      this.setState({modalLink: target.file.url, modalTitle: title})
    }
  }
  resourceCloser() {
    this.setState({modalLink: null, modalTitle: null, loadingIframe: true})
  }

  hideLoader() {
    this.setState({loadingIframe: false})
  }
  handleKeyDown = (e) => {
    if((e.keyCode === 27 || e.keyCode === 32 || e.keyCode === 13) && this.state.modalLink !== null) {
      e.preventDefault()
      this.setState({modalLink: null, modalTitle: null, loadingIframe: true})
    }
    if (e.keyCode === 9 && this.state.modalLink !== null) {
      e.preventDefault()
      document.getElementById("resource-modal-close").focus()
    }
    if (e.keyCode === 38 && this.state.modalLink !== null && this.state.loadingIframe !== true) {
      e.preventDefault()
    }
    if (e.keyCode === 40 && this.state.modalLink !== null && this.state.loadingIframe !== true) {
      e.preventDefault()
    }
  }
  focused() {
    document.getElementById("resource-modal-close").focus()
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown)
    const isLoggedIn = get(this, "props.location.state.loggedIn")
    if(!isLoggedIn) {
      let path = "/portal/"
      navigate(path)
    }
    const codes = document.getElementsByTagName("code")
    for (var i = codes.length - 1; i >= 0; i--) {
      const codeCont = codes[i]
      const codeSup = document.createElement("sup")
      const codePar = codeCont.parentElement
      codeSup.innerHTML = codeCont.innerHTML
      codePar.insertBefore(codeSup, codeCont)
      codeCont.remove()
    }
  }

  render() {
    // Component Content
    const heroContent = get(this, "props.data.contentfulHero")
    const resources = get(this, "props.data.allContentfulToolkitResource").nodes
    const simpasMag = get(this, "props.data.simpasMag")

    return (
      <Layout bodyClass="toolkit" loggedTF="loggedIn">
        <Seo title="Retailer Portal" description="Promotional tools for SIMPAS-applied Solutions retailers to connect with customers across channels. Login required." />
        <section className="padding--none">
          <Hero data={heroContent} loggedFlag={this.state.loggedFlag} />
        </section>
        <section className={this.state.modalLink !== null ? "bg--gray modal-active" : "bg--gray"} id="toolkit-resources">
          <div id="resource-modal">
            <button id="resource-modal-close" onClick={() => this.resourceCloser()} tabindex="-1">X</button>
            <iframe src={this.state.modalLink !== null ? this.state.modalLink+"#toolbar=0" : ""} frameborder="0" title={this.state.modalTitle+" PDF"} id="resource-modal-view" class={this.state.loading === false ? "loaded" : ""} onLoad={() => this.hideLoader()} tabindex="-1">
              Your browser does not support PDF's. To view this PDF, please download it:
              <a href={this.state.modalLink} target="_blank" rel="noreferrer" aria-label="Download PDF" class="btn">Download PDF</a>
            </iframe>
          </div>
          <div className="container" onFocus={this.focused}>
            <ul>
            {resources.map((item, index) => {
              return (
                <li key={item.title} id={'resource-' + index} className="resource-single">
                  <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                  {renderRichText(item.description)}
                  <a href={item.largeFileZipLink !== null ? "/videos/"+item.largeFileZipLink : item.fileZip !== null ? item.fileZip.file.url : item.file.file.url} className="btn" target="_blank" rel="noreferrer" aria-label="Download Resources">Download</a>
                  <button className="view" aria-label="View PDF" onClick={() => this.resourceHandler(item.file,item.title,item.largeFileLink)}><BackgroundImage
                    tag="span"
                    fluid={simpasMag.fluid}
                    style={{
                      backgroundPosition: 'left center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '25px 25px'
                    }}
                  >
                  View
                  </BackgroundImage></button>
                </li>
              )
            })}
            </ul>
          </div>
        </section>
      </Layout>
    )
  }
}
export default PortalTookit

export const PortalTookitQuery = graphql`
  query toolkitPageQuery {
    allContentfulPortalPassword {
      nodes {
        id
        password
      }
    }
    contentfulHero(heroTitle: { eq: "Retailer Toolkit" }, toolkit: { eq: true }) {
      id
      heroTitle
      heroSubtitle
      heroDescription {
        heroDescription
      }
      backgroundImage {
        fluid(maxWidth: 1800, background: "#000", quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      backgroundImageMobile {
        fluid(maxWidth: 640, background: "#000", quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      foregroundImage {
        file {
          url
        }
        description
      }
      modalLink
      modalButtonText
      toolkit
    }
    allContentfulToolkitResource(sort: {fields: order, order: ASC}) {
      nodes {
        file {
          title
          file {
            url
            fileName
            contentType
          }
          description
        }
        fileZip {
          title
          file {
            url
            fileName
            contentType
          }
          description
        }
        title
        description {
          raw
        }
        largeFileZipLink
        largeFileLink
      }
    }
    simpasMag: contentfulAsset(title: {eq: "simpas-mag"}) {
      id
      description
      title
      fluid(maxWidth: 68, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
      file {
        url
      }
    }
  }
`
